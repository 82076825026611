<template>
	<!--begin::Content-->
	<div class="h-100">
		<!--begin::Card-->
		<div class="card card-custom" style="height: 100%">
			<!--begin::Form-->
			<div class="card-body d-flex flex-column justify-content-between">
				<div>
					<!-- LINK FORM -->
					<div class="row">
						<h5 class="card-title font-weight-bolder text-dark mb-6" style="width: 100%; text-align: center">
							Link Site to Account:
						</h5>
					</div>
					<form class="form">
						<div class="form-group row">
							<div style="display: flex; width: 100%">
								<select class="form-control form-control-solid" v-model="account">
									<option v-for="account in adminSite.data.accountlist" :key="account.AccountID" :value="account">
										{{ account.name }} ({{ account.username }})
									</option>
								</select>
								<div class="input-group-append">
									<button :disabled="!account.AccountID" type="reset" class="btn btn-success ml-2" @click.prevent="addAccountLink">
										Add
									</button>
								</div>
							</div>
						</div>
					</form>
					<div class="separator separator-dashed my-5"></div>

					<ITCSpinner :loaded="loaded">
						<!-- ACCOUNTS LINKED -->
						<div class="row">
							<h5 class="card-title font-weight-bolder text-dark mb-6" style="width: 100%; text-align: center">
								Accounts linked to site:
							</h5>
							<div>
								<div v-for="(account, index) in adminSite.data.accounts" :key="index" class="account-link">
									<i class="fa fa-times-circle mr-2" @click.prevent="removeAccountLink(account.AccountID, index)" />{{ account.name }} ({{
										account.username
									}})
								</div>
							</div>
						</div>
					</ITCSpinner>
				</div>

				<div class="form-group row" style="text-align: center">
					<b-button variant="outline-danger" style="margin: 0 auto" @click.prevent="removeAllAccountLinks">
						Remove All Account Links
					</b-button>
				</div>
			</div>
			<!--end::Form-->
		</div>
		<!--end::Card-->
	</div>
	<!--end::Content-->
</template>

<script>
import { mapGetters } from 'vuex';
import { REMOVE_SITE_ACCOUNTLINK, ADD_SITE_ACCOUNTLINK } from '@/core/services/store/admin.module';
import ITCSpinner from '@/view/content/loaders/itcSpinner.vue';

export default {
	name: 'SiteProfileAccountLinks',
	data() {
		return {
			loaded: true,
			account: {},
		};
	},
	components: {
		ITCSpinner,
	},
	computed: {
		...mapGetters(['adminSite']),
	},
	methods: {
		addAccountLink() {
			this.loaded = false;
			this.$http
				.get(`addsiteaccount/${this.account.AccountID}/${this.adminSite.id}`)
				.then(res => {
					if (res.status === 200) {
						this.$store.commit(ADD_SITE_ACCOUNTLINK, {
							AccountID: this.account.AccountID,
							name: this.account.name,
							username: this.account.username,
						});
						this.account = {};
						this.loaded = true;
					}
				})
				.catch(res => {
					console.log('error', res);
					this.account = {};
				});
		},
		removeAccountLink(accountId, index) {
			this.$http
				.post('removesite', { id: this.adminSite.id, AccountId: accountId })
				.then(res => {
					if (res.status === 200) {
						this.$store.commit(REMOVE_SITE_ACCOUNTLINK, { index: index });
					}
				})
				.catch(res => {
					console.log('error', res);
				});
		},
		removeAllAccountLinks() {
			if (confirm('Are you sure you want to permanently remove all links to this site?')) {
				this.loaded = false;
				this.adminSite.data.linkedAccounts.forEach(link => {
					this.$http.post('removesite', { id: link.SiteId, AccountId: link.AccountId }).then(res => {
						if (res.data.data === 'Site removed.') {
							this.$store.commit(REMOVE_SITE_ACCOUNTLINK, link);
						}
						this.loaded = true;
					});
				});
			}
		},
	},
};
</script>

<style>
.account-link i {
	color: #f64f60;
}
.account-link:hover {
	color: #f64f60;
	cursor: pointer;
	margin: 0 5px;
}
</style>
